// src/App.js
import React, { useState, useEffect, useRef } from 'react';
import { Storage, Auth } from 'aws-amplify'
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'

import Button from './functions/Button';
import moveObj from './functions/moveObj';
import putTags from './functions/putTags';

import meterGuide from "./meterGuide.png"

const BUCKETNAME = "ccamplifystorage162038-dev";
const version = "1.10";

function App() {

  const text1 = useRef();

  //Current image selected
  const [current, setCurrent] = useState(0)
  const [newCurrent, setNewCurrent] = useState(0)

  //Lgoed in user
  const [user, setUser] = useState('')
  useEffect(() => {
    checkUser();
  },[])

  //Moves the counter through the s3 keys
  const [imageKey, setImageKey] = useState('')
  useEffect(() => {
    Storage.list('', {level: "public"})
    .then(res => {
        var flr = Math.floor((res.length-1)/2);
        //Last image doesn't load unless refreshed. 
        try{
          console.log("length: ",res.length-1)
          console.log("flr: ",flr)
          console.log("current: ", current)
          if (current <= flr){
            setImageKey(res[current].key)
            setNewCurrent(current)
          }
          else{
            setCurrent(prevCount => 0)
          }
        }
        catch{
          //only catches because it goes into infinite loop... 
          alert("You are currently caught up! Please close this session");
        }
    });
  }, [current, user])

  //gets the image based on the key and current value
  const [image, setImage] = useState([])
  useEffect(() => {
    fetchImage(newCurrent);
  }, [newCurrent])

  //fetches image based on current count
  async function fetchImage(current){
    // Fetch list of images from S3
    console.log("running fetchImage", current);
    const s3images = Storage.list('')
    .then(res => 
      Storage.get(res[current].key)
    );
    setImage(await s3images)
    text1.current.focus();
  }

  //user auth
  async function checkUser(){
    const user = await Auth.currentAuthenticatedUser();
    //console.log('username: ', user.username)
    setUser(user)
  }

  async function submitData() {

    var meterNumValue = document.getElementById("meterNum").value;
    var readNumValue = document.getElementById("readNum").value;

    //clears radio buttons after saving the values
    var meterModel, meterFormat;
    const rbs_type = document.querySelectorAll('input[name="METERMODEL"]');
    for (const rb of rbs_type){
      if(rb.checked){
            meterModel = rb.value;
            rb.checked = false;
      }
    }

    const rbs_num = document.querySelectorAll('input[name="METERFORMAT"]');
    for (const rb of rbs_num){
      if(rb.checked){
            meterFormat = rb.value;
            rb.checked = false;
      }
    }

    //put params to saves data as tags on the image in s3
    var putParams = {
        "S3_folder": BUCKETNAME,
        "S3_Object": "public/" + imageKey,
        "tags_to_add": { "meter": meterNumValue, "read":readNumValue, "METERMODEL":meterModel, "METERFORMAT":meterFormat }
    }

    //move params for moving image to final
    var moveParams = {
       "S3_folder_source": BUCKETNAME,
       "S3_Object_source": "public/" + imageKey,
       "S3_folder_destination": BUCKETNAME,
       "S3_Object_destination": "final/" + user.username + "-" + imageKey
    }

    putTags(putParams)
    .then(() => {
      moveObj(moveParams);
    })
    .then(() => { setCurrent(prevCount => prevCount + 1);
    });

    //clears values in text boxes
    document.getElementById("meterNum").value = '';
    document.getElementById("readNum").value = '';
  } 

  async function dataUnknown(){

    //prompts user for input
    const reason = await prompt("Please enter reason")

    console.log(reason)
    if (reason !== null){

      //resets radio buttons
      const rbs_type = document.querySelectorAll('input[name="METERMODEL"]');
      for (const rb of rbs_type){
        if(rb.checked){
              rb.checked = false;
        }
      }
      const rbs_num = document.querySelectorAll('input[name="METERFORMAT"]');
      for (const rb of rbs_num){
        if(rb.checked){
              rb.checked = false;
        }
      }

      var putParams = {
        "S3_folder": BUCKETNAME,
        "S3_Object": "public/" + imageKey,
        "tags_to_add": { "reason" : reason}
      }

      var moveParams = {
        "S3_folder_source": BUCKETNAME,
        "S3_Object_source": "public/" + imageKey,
        "S3_folder_destination": BUCKETNAME,
        "S3_Object_destination": "unknown/" + imageKey
      }
      
      //saves reason then moved image to unknown folder
      putTags(putParams)
      .then(() => moveObj(moveParams))
      .then(() => { setCurrent(prevCount => prevCount + 1)});

      //resets textboxes
      document.getElementById("meterNum").value = '';
      document.getElementById("readNum").value = '';
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <img src={image}  style={{width: 400, marginBottom: 10}} alt="meter" />

          <div style={{display: 'flex', flexDirection: 'column', margin:'15px'}}>
            <b>Meter Type</b>
            <div style={{display: 'flex', flexDirection: 'row', margin:'5px'}}>
              <label><input 
                type="radio"
                name="METERMODEL"
                value="Gas"
              /> Gas &nbsp; &nbsp;</label>
              <label><input 
                type="radio"
                name="METERMODEL"
                value="Electricity"
              /> Electricity </label>
            </div>
            <b>Number of Dials<br/>(For digital - Number<br/>of total possible digits)</b>
            <div style={{display: 'flex', flexDirection: 'row', margin:'5px'}}>
              <label><input 
                type="radio"
                name="METERFORMAT"
                value="3.0"
              /> 3.0 &nbsp; </label>
              <label><input 
                type="radio"
                name="METERFORMAT"
                value="4.0"
              /> 4.0 &nbsp; </label>
              <label><input 
                type="radio"
                name="METERFORMAT"
                value="5.0"
              /> 5.0 &nbsp; </label>
              <label><input 
                type="radio"
                name="METERFORMAT"
                value="6.0"
              /> 6.0 &nbsp; </label>
            </div>
            <label><b>Read</b></label>
            <input 
              type="text" 
              pattern="\d*(.\d{0,4})?$"
              id="readNum" 
            />
            <label><b>Meter Number</b></label>
            <input
              type="text"
              name="meter number"
              maxLength="7"
              pattern="\d*"
              id="meterNum"
              autoFocus="autofocus"
              ref={text1}
              required/>
            <Button title = "Submit" onClick={() => { submitData();}} />
            <Button title="Data Unknown" onClick={() => {dataUnknown();}} />
          </div>
        </div>
          <AmplifySignOut />
          <label>Version: {version}</label>
          <img
            src={meterGuide}
            alt="meter guide"
            width="750" height="200"
          />
      </div>
    </div>
  );
}

export default withAuthenticator(App);