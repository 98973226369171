/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://6mbque7gifgstlywbjjsyvn2am.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-67mdir5u7rfsxjbdhmc3p6iuky",
    "aws_cognito_identity_pool_id": "us-west-2:1cad5ba0-b974-446c-a331-0566f59aefa1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_QAMcKytHs",
    "aws_user_pools_web_client_id": "7qqpg4kbnoss68ht3mqbdb3vsm",
    "oauth": {},
    "aws_user_files_s3_bucket": "ccamplifystorage162038-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
